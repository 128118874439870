
import { Component, Vue } from "vue-property-decorator";
import DialogBox from "@/components/DialogBox.vue";
import Pagination from "@/components/Pagination.vue";
import { AuditTypes, PagedResultOfAuditLogDto } from "@/api-client";
import { handleQueries } from "@/utils/handleQueries";
import { auditTypes, userActivityGet } from "@/api/user-activity";
import { formatDate } from "@/utils/formatDate";
import { appendLeadingZeroes } from "@/utils/index";
import { debounce } from 'vue-debounce'

@Component({
  name: "UserActivity",
  components: {
    DialogBox,
    Pagination,
  },
  filters: {
    statusFilter: (status: string) => {
      const statusMap: { [key: string]: string } = {
        false: "danger",
        true: "success",
        null: "success",
      };
      return statusMap[status];
    },
  },
})
export default class extends Vue {
  tableData: PagedResultOfAuditLogDto = {
    totalCount: 0,
    pageCount: 0,
    pageSize: 0,
    pageNumber: 0,
    data: [],
  };
  filter: string = "";
  auditType: AuditTypes | "" = "";
  sortDirection: "Asc" | "Desc" = "Desc";
  pageNumber = 1;
  pageSize = 10;
  // linkTo: { type: AuditTypes; linkName: string }[] = [
  //   {
  //     type: "ApplicationUserRoles",
  //     linkName: "",
  //   },
  //   {
  //     type: "Payments",
  //     linkName: "OrderDetail",
  //   },
  //   {
  //     type: "Products",
  //     linkName: "ProductDetail",
  //   },
  //   {
  //     type: "OrderHistory",
  //     linkName: "OrderDetail",
  //   },
  //   {
  //     type: "OrderInvoices",
  //     linkName: "OrderDetail",
  //   },
  //   {
  //     type: "OrderProducts",
  //     linkName: "OrderDetail",
  //   },
  //   {
  //     type: "Orders",
  //     linkName: "OrderDetail",
  //   },
  //   {
  //     type: "Users",
  //     linkName: "UserDetail",
  //   },
  // ];
  dFn = debounce((val:Function) => val(), '600ms')

  get auditTypesList() {
    return auditTypes;
  }

  async created() {
    let toQuery = {
      filter: this.filter,
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
      sortDirection: this.sortDirection,
      auditType: this.auditType,
    };
    this.setQueryWatch(toQuery);

    this.loadInitialData();
  }

  mounted() {
    setTimeout(() => {
      document.getElementById("search")?.focus();
    }, 0);
  }

  setQueryWatch(fields: any) {
    let queries = this.$route.query;

    for (const field in fields) {
      if (queries[field]) {
        (this as any)[field] = queries[field];
      }

      this.$watch(field, (val) => {
        handleQueries(field, val);
      });
    }
  }

  handleJson(value: string | null) {
    if (value) {
      let json;
      let isJson = false;

      try {
        json = JSON.parse(value);
        if (typeof json == "object") {
          isJson = true;
        }
      } catch (error) {
        isJson = false;
      }

      if (isJson && json) {
        let convertedJson = [];
        for (const [key, value] of Object.entries(json)) {
          if (value) {
            convertedJson.push(`<u>${key}</u>: ${value}`);
          }
        }
        return convertedJson.join("<br>");
      }
      return value;
    }
    return null;
  }

  handleDate(date: any) {
    return formatDate(date);
  }

  handleTime(date: any) {
    let hour = new Date(date).getHours();
    let minutes = new Date(date).getMinutes();

    return `${appendLeadingZeroes(hour)}:${appendLeadingZeroes(minutes)}`;
  }

  handleTypeName(type: AuditTypes) {
    const match = auditTypes.find((item) => item.id == type);
    if (match) {
      return match.name;
    }
    return type;
  }

  // routerType(row:AuditLogDto) {
  //   // TODO: remove as any when renier fixes auditLogTypesId
  //   const match = this.linkTo.find(item => item.type == row.auditLogTypesId as any)
  //   if(match && match.linkName) {
  //     return { name: match.linkName, params: { id: row.typePrimaryKey.toLowerCase() }}
  //   }
  //   return null;
  // }

  changeSortDirection() {
    this.sortDirection = this.sortDirection == "Asc" ? "Desc" : "Asc";
    this.pageNumber = 1;
    this.loadInitialData();
  }

  handleFilter() {
    // if(isClear || this.filter.length >= 3) {
      // this.dFn(() => {
        this.pageNumber = 1;
        this.loadInitialData().then(() => {
          setTimeout(() => {
            document.getElementById("search")?.focus();
          }, 0);
        })
      // })
    // }
  }

  handlePageNumberClick(page: any) {
    this.pageNumber = page;
    this.loadInitialData();
  }

  handlePageSizeChange(size: number) {
    this.pageNumber = 1;
    this.pageSize = size;
    this.loadInitialData()
  }

  async loadInitialData() {
    await userActivityGet(this.pageNumber, this.pageSize, this.auditType || undefined, this.filter || undefined, this.sortDirection).then(async (res) => {
      this.tableData = res;
    });
  }
}
